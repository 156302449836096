import { Directive, HostListener, Renderer2, ElementRef } from "@angular/core";

@Directive({
  selector: "[appRipple]",
  standalone: true,
})
export class RippleDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent) {
    const button = this.el.nativeElement;
    const rippleEffect = this.renderer.createElement("span");

    let rgbaColor = "rgba(255, 255, 255, 0.2)";

    // Get the divs in the button

    const divs = button.getElementsByTagName("div");

    const styles = window.getComputedStyle(button);
    rgbaColor = styles.borderColor;
    // Set the opacity to 0.2
    // Split the string at , and replace the last element with 0.2

    // remove parenthesis
    rgbaColor = this.removeParenthesis(rgbaColor);

    const rgbaColorArray = rgbaColor.split(",");
    if (rgbaColorArray.length < 4) {
      rgbaColorArray.push("0.2");
    } else {
      rgbaColorArray[rgbaColorArray.length - 1] = "0.2";
    }

    rgbaColorArray[rgbaColorArray.length - 1] = "0.2";

    rgbaColor = rgbaColorArray.join(",");

    rgbaColor = `rgba(${rgbaColor})`;

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);

    const left = event.clientX - rect.left - size / 2;
    const top = event.clientY - rect.top - size / 2;

    this.renderer.setStyle(rippleEffect, "background", rgbaColor);

    this.renderer.addClass(rippleEffect, "ripple-effect");

    this.renderer.setStyle(rippleEffect, "width", `${size}px`);
    this.renderer.setStyle(rippleEffect, "height", `${size}px`);
    this.renderer.setStyle(rippleEffect, "left", `${left}px`);
    this.renderer.setStyle(rippleEffect, "top", `${top}px`);

    this.renderer.appendChild(button, rippleEffect);
    rippleEffect.addEventListener("animationend", () => {
      this.renderer.removeChild(button, rippleEffect);
    });
  }

  private removeParenthesis(str: string) {
    return str
      .replace("(", "")
      .replace(")", "")
      .replace("rgb", "")
      .replace("rgba", "");
  }
}
