<canvas id="matrix" #matrix class="absolute matrix"></canvas>
<div
  class="h-screen absolute z-10 w-full flex justify-center items-center text-white-primary"
>
  <div
    class="bg-black-secondary w-[500px] p-7 flex flex-col text-center rounded-sm font-chakra shadow-account"
  >
    <div class="text-xl">{{ title }}</div>
    <img
      src="assets/icons/empty-table.svg"
      class="w-[400px] h-[200px] self-center"
      alt=""
    />
    <div class="text-lg self-center">{{ message }}</div>
    <div i18n="@@goToLoginPage">
      Go to
      <a
        (click)="goToLoginPage()"
        class="text-green-primary hover:underline cursor-pointer"
        >Login</a
      >
      page
    </div>
  </div>
</div>
