<a routerLink="/" class="lg:flex hidden items-center">
  <img
    [src]="environment.settings.logoPath"
    alt="logo"
    class="h-[24px] max-w-[115px] object-contain"
  />
  <span *ngIf="co_branding" class="text-white-primary text-sm mr-2 ml-2"
    >x</span
  >
  <img
    *ngIf="co_branding"
    [src]="backendAccount + '/storage/' + user?.company?.logo_url"
    alt="logo-company"
    class="max-h-[30px] h-[24px] max-w-[115px] object-contain"
  />
  <div class="mx-4">
    <img src="assets/icons/separator-logo.svg" alt="separator-logo" />
  </div>
  <img
    src="assets/icons/logo-account.svg"
    class="h-[24px] max-w-[115px] object-contain"
  />
</a>
<a routerLink="/" class="lg:hidden flex ml-2 items-center">
  <img
    [src]="environment.settings.logoMobilePath"
    alt="logo-mobile"
    class="h-[24px] max-w-[115px] object-contain"
  />
  <span *ngIf="co_branding" class="text-white-primary text-sm mr-2 ml-2"
    >x</span
  >
  <img
    *ngIf="co_branding"
    [src]="backendAccount + '/storage/' + user?.company?.logo_url"
    alt="logo-company"
    class="max-h-[30px] max-w-[115px] object-contain"
  />
  <div class="mx-4">
    <img src="assets/icons/separator-logo.svg" alt="separator-logo" />
  </div>
  <img
    src="assets/icons/logo-account.svg"
    class="h-[24px] max-w-[115px] object-contain"
  />
</a>
