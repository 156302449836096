import { NgModule } from "@angular/core";
import { RouterModule, ROUTES } from "@angular/router";
import {
  AuthGuardService,
  UnauthGuardService,
} from "@services/auth/auth-guard.service";
import { DefaultRouteService } from "@services/auth/default-route.service";
import { RoleGuardService } from "@services/auth/role-guard.service";
import { MobileRedirectGuardService } from "@services/mobile-redirect-guard.service";
import { RolesService } from "@services/roles.service";
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
import { AccountNotActiveComponent } from "@components/account-not-active/account-not-active.component";

function routesFactory(rolesService: RolesService) {
  return [
    {
      path: "login",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/login/login.module").then((m) => m.LoginModule),
    },
    {
      path: "login/validate",
      // canActivate: [UnauthGuardService],
      pathMatch: "full",
      loadChildren: () =>
        import("./pages/login-validate/login-validate.module").then(
          (m) => m.LoginValidateModule
        ),
    },
    {
      path: "login/:company_code",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/login/login.module").then((m) => m.LoginModule),
    },

    {
      path: "register",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/signup/signup.module").then((m) => m.SignupModule),
    },
    {
      path: "register/:company_code",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/signup/signup.module").then((m) => m.SignupModule),
    },

    {
      path: "password/forget",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/forget-password/forget-password.module").then(
          (m) => m.ForgetPasswordModule
        ),
    },
    {
      path: "account/confirm/:token",
      // canActivate: [MobileRedirectGuardService],
      component: ConfirmAccountComponent,
    },
    {
      path: "password/reset/:token",
      canActivate: [UnauthGuardService],
      loadChildren: () =>
        import("./pages/reset-password/reset-password.module").then(
          (m) => m.ResetPasswordModule
        ),
    },
    {
      path: "mobile-redirect",
      loadChildren: () =>
        import("./pages/mobile-redirect/mobile-redirect.module").then(
          (m) => m.MobileRedirectModule
        ),
    },
    {
      path: "home",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],
      loadChildren: () =>
        import("./pages/user/home/home.module").then((m) => m.HomeModule),
      data: {
        roles: ["user", "company-member"],
      },
    },

    {
      path: "company",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/company-manager/company/company.module").then(
          (m) => m.CompanyModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "subscribe",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/subscribe/subscribe.module").then(
          (m) => m.SubscribeModule
        ),
      data: {
        roles: ["user"],
      },
    },
    {
      path: "account-issue/:error",
      component: AccountNotActiveComponent,
    },
    {
      path: "company/groups",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/company-manager/groups/groups.module").then(
          (m) => m.GroupsModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/classrooms",
      canActivate: [AuthGuardService, RoleGuardService],

      loadChildren: () =>
        import("./pages/company-manager/classrooms/classrooms.module").then(
          (m) => m.ClassroomsModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/wallet",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/company-manager/wallet/wallet.module").then(
          (m) => m.WalletModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "company/wallet",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/company-manager/wallet/wallet.module").then(
          (m) => m.WalletModule
        ),
      data: {
        roles: ["company-manager"],
      },
    },
    {
      path: "profile",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/user/general-infos/general-infos.module").then(
          (m) => m.GeneralInfosModule
        ),

      data: {
        roles: ["user", "company-member", "company-manager", "super-admin"],
      },
    },
    {
      path: "user/wallet",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/user/wallet/wallet.module").then((m) => m.WalletModule),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "user/group",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/user/group/group.module").then((m) => m.GroupModule),
      data: {
        roles: ["user", "company-member"],
      },
    },
    {
      path: "plans/upgrade",
      canActivate: [
        MobileRedirectGuardService,
        AuthGuardService,
        RoleGuardService,
      ],

      loadChildren: () =>
        import("./pages/upgrade-plan/upgrade-plan.module").then(
          (m) => m.UpgradePlanModule
        ),
      data: {
        roles: ["user"],
      },
    },

    {
      path: "",
      redirectTo: "",
      canActivate: [DefaultRouteService],
      pathMatch: "full",
    },

    {
      path: "404",
      loadChildren: () =>
        import("./pages/four-o-four/four-o-four.module").then(
          (m) => m.FourOFourModule
        ),
    },
    {
      path: "social/callback/:provider",
      // canActivate: [UnauthGuardService],

      loadChildren: () =>
        import("./pages/social-redirect/social-redirect.module").then(
          (m) => m.SocialRedirectModule
        ),
    },
    {
      path: "**",
      redirectTo: "404",
    },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot([])],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [RolesService],
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
