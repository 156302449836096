<!--toast.component.html-->
<div
  [@toastTrigger]="showsToast ? 'open' : 'close'"
  [ngClass]="toastClass"
  class="p-4 bg-black-tertiary"
>
  <div class="flex justify-between items-center">
    <div class="mr-4" *ngIf="iconPath">
      <svg-icon src="{{ iconPath }}"></svg-icon>
    </div>
    <div class="flex flex-col mr-4">
      <h3 class="text-white-primary text-sm font-semibold leading-5">
        {{ toastTitle }}
      </h3>
      <p class="text-gray-primary leading-5 text-sm">{{ toastDescription }}</p>
    </div>

    <small (click)="closeToast()" class="cursor-pointer">
      <svg-icon src="assets/icons/close.svg" class="white-icon"></svg-icon>
    </small>
  </div>
</div>
