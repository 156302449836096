<div
  class="shadow-card bg-black-secondary border rounded border-black-primary w-[400px]"
>
  <div class="modal-content">
    <div class="p-6 modal-body flex flex-col justify-center items-center">
      <div
        class="p-[9px] mr-3 {{
          neutral ? 'bg-green-secondary' : 'bg-red-primary'
        }} bg-opacity-[0.07] rounded-full w-12 h-12 flex items-center justify-center mb-5"
      >
        <svg-icon
          class=""
          src="assets/icons/{{ neutral ? 'check' : 'close-red' }}.svg"
        ></svg-icon>
      </div>
      <h2 class="text-lg font-semibold text-white-primary mb-2">
        {{ title }}
      </h2>
      <p class="text-sm text-gray-primary leading-5">
        {{ description }}
      </p>
    </div>

    <div class="flex items-center justify-between p-6 modal-footer">
      <button
        appRipple
        (click)="ref.close(false)"
        class="w-[170px] py-[10px] hover:border-[#282E38] hover:bg-black-tertiary text-sm leading-5 rounded-lg outline-none cursor-pointer border border-black-primary text-white-primary"
      >
        <span class="capitlize"> {{ cancelText }}</span>
      </button>
      <button
        appRipple
        (click)="ref.close(true)"
        class="{{
          neutral ? 'bg-green-secondary' : 'bg-red-primary'
        }} w-[170px] py-[10px] text-sm leading-5 rounded-lg outline-none cursor-pointer text-white-primary"
      >
        <span class="capitalize"> {{ confirmText }}</span>
      </button>
    </div>
  </div>
</div>
