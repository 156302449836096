import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { AuthService } from "@services/auth/auth.service";
import { RedirectionService } from "@services/redirection.service";

@Component({
  selector: "app-confirm-account",
  templateUrl: "./confirm-account.component.html",
  styleUrls: ["./confirm-account.component.scss"],
})
export class ConfirmAccountComponent implements OnInit {
  @ViewChild("captchaElem") captchaElem: any;
  token = "";
  siteKey = environment.settings.recaptchaSiteKeyV2;
  stopRedirect = false;
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly redirectionService: RedirectionService
  ) {}

  ngOnInit(): void {
    this.token = this.router.url.split("/")[3];
    this.redirectionService.redirection$.subscribe(() => {
      this.stopRedirect = true;
    });

    if (!this.siteKey) {
      this.confirmAccount();
    }
  }

  confirmAccount() {
    this.authService.confirmAccount(this.token).subscribe({
      next: (res) => {
        this.router.navigate(["/home"]);
      },
      error: (err) => {
        if (this.stopRedirect) {
          return;
        }
        this.router.navigate(["/register"]);
      },
    });
  }

  handleCaptcha() {
    setTimeout(() => {
      // This is a workaround for the captcha to work
      this.captchaElem.resetCaptcha();
      this.captchaElem.execute();
    }, 50);
  }

  handleSuccess($event: string) {
    localStorage.setItem("recaptcha", $event);
    setTimeout(() => {
      this.confirmAccount();
    }, 50);
  }
}
