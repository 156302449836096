import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { GenericResp, GenericResponse } from "@interfaces/generic-resp";
import { RefreshTokenResp } from "@interfaces/refresh-token";
import { UserData, UserDataResp } from "@interfaces/user";
import { ErrorHandlingService } from "@services/error-handling.service";
import { FeatureFlagsService } from "@services/feature-flags.service";
import { JwtService } from "@services/jwt.service";
import { RolesService } from "@services/roles.service";
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  Observable,
  throwError,
} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubj: BehaviorSubject<UserData | null> =
    new BehaviorSubject<UserData | null>(null);
  private currentCompanySubj: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private captchaHeader = {
    headers: {
      "X-Captcha": "",
    } as any,
  };

  currentUser$: Observable<UserData | null>; // Other components can subscribe to this to get notified of changes
  isRefreshingUser = false;
  isLoginSSO = false;
  companyDetails$: Observable<any | null>;

  constructor(
    private readonly http: HttpClient,
    private readonly ehs: ErrorHandlingService,
    private readonly router: Router,
    private readonly jwtService: JwtService,
    private readonly rolesService: RolesService,
    private featurFlagsService: FeatureFlagsService
  ) {
    this.currentUser$ = this.currentUserSubj
      .asObservable()
      .pipe(filter((user: UserData | null) => !!user));

    this.companyDetails$ = this.currentCompanySubj
      .asObservable()
      .pipe(filter((company: any | null) => !!company));
  }

  get currentUserData(): UserData | null {
    return this.currentUserSubj.value;
  } // getter of the value of the currently logged in user

  get isLoggedIn(): boolean {
    return !!this.currentUserData;
  }

  refreshUserData() {
    if (this.retrieveUserFromLocalStorage() && !this.isRefreshingUser) {
      // Refresh user data only if userData exists in localStorage and auth service is not already refreshing user
      this.isRefreshingUser = true;
      if (environment.settings.debuging) {
        console.log(
          "[Auth - Service] %cRetrieving user data from server",
          "color:orange"
        );
      }
      this.http
        .get<UserDataResp>(
          `${environment.backend.account}/api/auth/getAuthenticatedUser`,
          { headers: { ignoreLoadingInterceptor: "true" } }
        )
        .subscribe({
          next: (resp) => {
            if (resp.success) {
              const userData = this.transformUser(resp.data);
              this.jwtService.jwt = resp.data.token;
              localStorage.setItem("currentUser", JSON.stringify(userData));
              this.currentUserSubj.next(userData);
              this.rolesService.currentUserRoles$.next(userData.user.roles);
              if (environment.settings.debuging) {
                console.log(
                  "[Auth - Service] %cUser data retrieved from server",
                  "co lor:green",
                  userData
                );
              }
            } else {
              this.ehs.debug(resp, "refreshUserData", "auth", "s");
            }
          },
          error: (err) => {
            if (environment.settings.debuging) {
              this.ehs.debug(err, "refreshUserData", "auth", "s");
            }
            if ((err as any) instanceof HttpErrorResponse) {
              if (
                err.error?.message === "account_disabled" ||
                err.errors?.errors === "account_disabled" ||
                err.error.message === "account_not_confirmed" ||
                err.error.errors === "unconfirmed_account" ||
                err.error.message === "company_account_not_approved"
              ) {
                return;
              }
            }
            this.logout("login");
          },
        })
        .add(() => (this.isRefreshingUser = false));
    }
  }

  loginSSO(data: {
    email: string;
    password: string;
  }): Observable<UserData | undefined> {
    this.captchaHeader.headers = {
      ...this.captchaHeader.headers,
    };
    return this.http
      .post<UserDataResp>(
        `${environment.backend.account}/api/auth/login`,
        data,
        this.captchaHeader
      )
      .pipe(
        map((resp: UserDataResp) => {
          if (resp.success) {
            if (resp.data.requires_2fa) {
              return resp.data;
            } else {
              const userData = this.transformUser(resp.data);
              this.jwtService.jwt = resp.data.token;
              localStorage.setItem("currentUser", JSON.stringify(userData));
              this.currentUserSubj.next(userData); // User object is published to all subscribers
              this.featurFlagsService.loadFeatureFlags("authenticated");
              this.rolesService.currentUserRoles$.next(userData.user.roles);
              return userData;
            }
          }
          this.ehs.debug(resp, "loginSSO", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "loginSSO", "auth", "s");
          throw error;
        })
      );
  }

  confirmLinkAccount(
    confirmationCode: string,
    provider: string
  ): Observable<UserData | undefined> {
    return this.http
      .post<UserDataResp>(
        `${environment.backend.account}/api/auth/social/${provider}/confirm-account-link`,
        {
          confirmation_code: confirmationCode,
        },
        this.captchaHeader
      )
      .pipe(
        map((resp: UserDataResp) => {
          if (resp.success) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);

            return userData;
          }
          this.ehs.debug(resp, "loginSSO", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "loginSSO", "auth", "s");
          throw error;
        })
      );
  }

  registerSSO(data: any) {
    return this.http.post(
      `${environment.backend.account}/api/auth/register`,
      data,
      this.captchaHeader
    );
  }

  logout(cause?: "login" | "home"): void {
    localStorage.removeItem("currentUser");
    this.currentUserSubj.next(null);
    if (cause === "login") {
      this.router.navigate(["/login"]);
    } else {
      window.location.href = environment.frontend.secdojo;
    }
  }

  logoutFromBackend(): Observable<boolean> {
    return this.http
      .post<GenericResponse<boolean>>(
        `${environment.backend.account}/api/auth/users/logout`,
        {}
      )
      .pipe(
        map((resp: GenericResponse<boolean>) => {
          localStorage.removeItem("currentUser");
          this.currentUserSubj.next(null);

          return resp.data;
        })
      );
  }

  refreshToken(): Observable<RefreshTokenResp> {
    return this.http
      .get<RefreshTokenResp>(
        `${environment.backend.account}/api/auth/refreshToken`,
        { headers: { ignoreLoadingInterceptor: "true" } }
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "refreshToken", "auth", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  getAuthorizationCode(redirect_url: string) {
    const data = { redirect_url: redirect_url };
    return this.http.post(
      `${environment.backend.account}/api/auth/login/validate`,
      data
    );
  }

  private retrieveUserFromLocalStorage(): boolean {
    const userDataJson = localStorage.getItem("currentUser");
    if (!userDataJson) {
      return false;
    }
    try {
      const userData = JSON.parse(userDataJson);
      if (userData) {
        this.currentUserSubj.next(userData);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  setUserData(userData: UserData) {
    this.jwtService.jwt = userData.token;
    localStorage.setItem("currentUser", JSON.stringify(userData));
    this.currentUserSubj.next(userData); // User object is published to all subscribers
    this.rolesService.currentUserRoles$.next(userData.user.roles);
  }

  private transformUser(data: UserData): UserData {
    const user = data.user;
    // user.avatarPath = 'assets/icons/ninja_profile.svg';
    return {
      user,
      token: data.token,
    };
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post(
      `${environment.backend.account}/api/auth/forgotPassword`,
      data,
      this.captchaHeader
    );
  }

  validateResetToken(token: any) {
    return this.http.get(
      `${environment.backend.account}/api/auth/resetPassword/validate/${token}`,
      this.captchaHeader
    );
  }

  resetPassword(data: any) {
    return this.http
      .post<UserDataResp>(
        `${environment.backend.account}/api/auth/resetPassword`,
        data,
        this.captchaHeader
      )
      .pipe(
        map((resp: UserDataResp) => {
          if (resp.success) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);

            return userData;
          }
          this.ehs.debug(resp, "loginSSO", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "loginSSO", "auth", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  confirmAccount(code: any) {
    return this.http
      .get(
        `${environment.backend.account}/api/auth/confirm/${code}`,
        this.captchaHeader
      )
      .pipe(
        map((resp: any) => {
          if (resp.success) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);

            return userData;
          }
          this.ehs.debug(resp, "loginSSO", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "loginSSO", "auth", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  updateProfile(data: any) {
    return this.http.post(
      `${environment.backend.account}/api/user/update`,
      data
    );
  }

  desactivateAccount() {
    return this.http.post(
      `${environment.backend.account}/api/user/disable`,
      {}
    );
  }

  publishNullUser() {
    this.currentUserSubj.next(null);
  }

  generateSocialRedirectUrl(provider: string) {
    return this.http.post(
      `${environment.backend.account}/api/auth/social/${provider}/redirect`,
      {}
    );
  }

  loginSocial(
    provider: string | null,
    authorization_code: string,
    langCode = "en"
  ) {
    return this.http
      .post<UserDataResp | any>(
        `${environment.backend.account}/api/auth/social/${provider}/login`,
        { code: authorization_code, lang_code: langCode }
      )
      .pipe(
        map((resp: UserDataResp | any) => {
          if (resp.success && !resp.data.email) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);
            return userData;
          }

          if (resp.data.token && resp.data.email) {
            localStorage.setItem("register-token", resp.data.token);
            localStorage.setItem("register-email", resp.data.email);
          }

          this.ehs.debug(resp, "loginSocial", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "loginSocial", "auth", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  registerSocial(provider: string | null, data: any) {
    return this.http
      .post<UserDataResp>(
        `${environment.backend.account}/api/auth/social/${provider}/register`,
        data
      )
      .pipe(
        map((resp: UserDataResp) => {
          if (resp.success) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);
            return userData;
          }
          this.ehs.debug(resp, "registerSocial", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "registerSocial", "auth", "s");
          return throwError(() => new Error(error));
        })
      );
  }
  //up or down

  toggleOpenToWork() {
    return this.http.post(
      `${environment.backend.account}/api/cyberhiring/user/toggle-open-to-work`,
      {}
    );
  }
  // Two factor authentication

  enable2FA(): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(
      `${environment.backend.account}/api/2fa/enable`,
      {}
    );
  }

  disable2FA(password: string): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/2fa/disable`,
        { password: password }
      )
      .pipe(
        map((resp: GenericResponse<any>) => {
          return resp;
        })
      );
  }
  getQrCode(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(
      `${environment.backend.account}/api/2fa/qr-code`
    );
  }
  getRecoveryCodes(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(
      `${environment.backend.account}/api/2fa/recovery-codes`
    );
  }
  regenerateRecoveryCodes(password: string): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(
      `${environment.backend.account}/api/2fa/recovery-codes/generate`,
      { password }
    );
  }

  confirm2faAction(
    code: string,
    password: string
  ): Observable<GenericResponse<boolean>> {
    return this.http.post<GenericResponse<boolean>>(
      `${environment.backend.account}/api/2fa/confirmed`,
      { code: code, password: password }
    );
  }
  challengeCodeMfa(
    user_id: string,
    code: string,
    recovery_code: string
  ): Observable<UserData | undefined> {
    return this.http
      .post<UserDataResp>(`${environment.backend.account}/api/2fa/challenge`, {
        user_id,
        code,
        recovery_code,
      })
      .pipe(
        map((resp: UserDataResp) => {
          if (resp.success) {
            const userData = this.transformUser(resp.data);
            this.jwtService.jwt = resp.data.token;
            localStorage.setItem("currentUser", JSON.stringify(userData));
            this.currentUserSubj.next(userData); // User object is published to all subscribers
            this.rolesService.currentUserRoles$.next(userData.user.roles);

            return userData;
          }

          this.ehs.debug(resp, "challenge2FA", "auth", "s");
          return;
        }),
        catchError((error: any) => {
          this.ehs.debug(error, "challenge2FA", "auth", "s");
          throw error;
        })
      );
  }

  getEmailCompanyCodeFromToken(token: string) {
    return this.http.post(
      `${environment.backend.account}/api/auth/email-company-code`,
      { token }
    );
  }

  setCompanyDetails(details: any) {
    this.currentCompanySubj.next(details);
  }
}
