<div
  class="w-[350px] md:w-[471px] min-h-[500px] relative shadow-card modal-content bg-black-secondary border rounded border-black-primary"
>
  <svg-icon src="assets/icons/hexagon-1.svg" class="absolute"></svg-icon>

  <div *ngFor="let step of content">
    <ng-container *ngIf="activeStep === step.id">
      <div
        class="background-{{
          step.id
        }} mt-6 relative z-20 flex justify-center flex-col items-center"
      >
        <div class="absolute top-[190px] text-center px-10">
          <span
            *ngIf="step.id > 1"
            i18n="@@onboardingModalWelcome"
            class="text-xs block mb-1 capitalize text-[#637387]"
            >welcome, <strong>{{ user?.name }}</strong></span
          >
          <h2
            class="font-chakra text-xl text-white-primary font-bold leading-8 mb-4"
          >
            {{ step.title }} {{ step.id === 1 ? user?.name : "" }}
          </h2>

          <p
            class="text-center font-inter text-sm text-gray-fourth leading-5 mb-9"
          >
            {{ step.description }}
          </p>

          <ul class="dots mb-6">
            <li
              (click)="goToStep(step.id)"
              *ngFor="let step of content"
              [ngClass]="{ active: activeStep === step.id }"
            ></li>
          </ul>

          <div class="flex items-center justify-center">
            <button
              *ngIf="step.id > 1"
              (click)="previousStep()"
              class="px-10 py-2 mr-2 text-sm leading-5 border rounded-sm outline-none border-black-secondary bg-black-secondary text-white-primary"
            >
              <span i18n="@@back">Back</span>
            </button>
            <button
              (click)="nextStep()"
              class="px-10 py-2 text-sm leading-5 rounded-sm outline-none bg-green-secondary text-white-primary"
            >
              <span i18n="@@next" *ngIf="step.id !== content.length">Next</span>
              <span i18n="@@close" *ngIf="step.id === content.length"
                >Close</span
              >
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- End Step 2 -->
</div>
