import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogRef } from "@ngneat/dialog";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RippleDirective } from "@directives/ripple.directive";
interface Data {
  title: string;
  description?: string;
  cancelText: string;
  confirmText: string;
  neutral?: boolean;
}
@Component({
  selector: "app-confirm-action-modal",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RippleDirective],
  templateUrl: "./confirm-action-modal.component.html",
  styleUrls: ["./confirm-action-modal.component.scss"],
})
export class ConfirmActionModalComponent {
  ref: DialogRef<Data> = inject(DialogRef);

  // constructor() {}
  get neutral() {
    return this.ref.data?.neutral;
  }

  get title() {
    if (!this.ref.data) return "";
    return this.ref.data.title;
  }
  get description() {
    if (!this.ref.data) return "";
    return this.ref.data.description;
  }
  get cancelText() {
    if (!this.ref.data) return "";
    return this.ref.data.cancelText;
  }
  get confirmText() {
    if (!this.ref.data) return "";
    return this.ref.data.confirmText;
  }
}
