<div
  class="shadow-card modal-content bg-black-secondary border rounded border-black-primary"
>
  <!-- Step 1 -->
  <div class="modal-content">
    <ul class="modal-body flex w-full gap-4 items-center flex-wrap">
      <li class="flex-1 hover:bg-black-tertiary p-6">
        <a
          href="{{ cyberLearnLink }}"
          alt="cyberlearn"
          class="flex flex-col items-center justify-center"
        >
          <svg-icon
            class="green-icon mb-3"
            src="assets/icons/cyberlearn.svg"
            [svgStyle]="{ 'height.px': 44, 'width.px': 50 }"
          ></svg-icon>
          <h3 class="text-white-primary text-sm leading-none">Cyberlearn</h3>
        </a>
      </li>
      <li class="flex-1 hover:bg-black-tertiary p-6">
        <a
          href="{{ cyberlabLink }}"
          alt="cyberlab"
          class="flex flex-col items-center justify-center"
        >
          <svg-icon
            class="green-icon mb-3"
            src="assets/icons/cyberlab.svg"
            [svgStyle]="{ 'height.px': 44, 'width.px': 50 }"
          ></svg-icon>
          <h3 class="text-white-primary text-sm leading-none">Cyberlab</h3>
        </a>
      </li>
      <li class="flex-1 p-6 grayscale">
        <div
          alt="cyberwar"
          class="flex flex-col items-center justify-center grayscale cursor-default"
        >
          <svg-icon
            class="green-icon mb-3"
            src="assets/icons/cyberwar.svg"
            [svgStyle]="{ 'height.px': 44, 'width.px': 50 }"
          ></svg-icon>
          <h3 class="text-white-primary text-sm leading-none">Cyberwar</h3>
        </div>
      </li>
      <li class="flex-1 hover:bg-black-tertiary p-6">
        <a
          href="{{ discordLink }}"
          alt="discord"
          class="flex flex-col items-center justify-center"
        >
          <svg-icon
            src="assets/icons/discord-1.svg"
            [svgStyle]="{ 'height.px': 44, 'width.px': 50 }"
            class="mb-3"
            discord
          ></svg-icon>
          <h3 class="text-white-primary text-sm leading-none">Discord</h3>
        </a>
      </li>
      <!-- <li class="flex-[0_0_50%] hover:bg-black-tertiary p-6">
        <a
          (click)="document.location.href = '/logout'"
          alt="logout"
          class="flex flex-col items-center justify-center cursor-pointer"
        >
          <svg-icon
            class="green-icon mb-3"
            src="assets/icons/logout-1.svg"
            [svgStyle]="{ 'height.px': 44, 'width.px': 50 }"
          ></svg-icon>
          <h3 class="text-white-primary text-sm leading-none">Logout</h3>
        </a>
      </li> -->
    </ul>
  </div>
</div>
