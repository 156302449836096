<div class="bg-black-tertiary p-4">
  <div class="text-white-primary text-sm mb-4">
    {{ planText }}
  </div>
  <div>
    <a
      (click)="upgrade()"
      routerLink="/subscribe"
      appRipple
      class="bg-[#f2dba2] w-full text-[#282E38] font-chakra py-2 px-2 rounded-sm font-semibold"
    >
      {{ buttonText }}
    </a>
  </div>
</div>
