import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgScrollbarModule } from "ngx-scrollbar";
import { NgxCaptchaModule } from "ngx-captcha";
import { AngularSvgIconModule } from "angular-svg-icon";

import { environment } from "@environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TabsComponent } from "@components/tabs/tabs.component";
import { JwtInterceptor } from "@interceptors/jwt.interceptor";
import { CaptchaInterceptor } from "@services/captcha-interceptor.service";
import { LoadingInterceptor } from "@interceptors/loading.interceptor";
import { ToastComponent } from "@components/toast/toast.component";
import { HttpErrorInterceptorService } from "@services/auth/http-error-interceptor.service";
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
import { ClarityModule } from "./analytics/clarity.module";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { RippleDirective } from "@directives/ripple.directive";
import { DropdownProfileMenuComponent } from "@components/dropdown-profile-menu/dropdown-profile-menu.component";
import { BackendErrorInterceptor } from "@interceptors/backend-error.interceptor";
import { WalletNavComponent } from "./components/wallet-nav/wallet-nav.component";
import { AppInitializeService } from "@services/app-initializer.service";
import { NavbarLogosSectionComponent } from "@components/navbar-logos-section/navbar-logos-section.component";
import { FeatureFlagDirective } from "@directives/feature-flag.directive";

import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { StatisticCardComponent } from "@components/statistic-card/statistic-card.component";

export function initializeApp(appInitService: AppInitializeService) {
  return () => appInitService.load();
}

@NgModule({
  declarations: [AppComponent, ConfirmAccountComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    AngularSvgIconModule.forRoot(),
    TabsComponent,
    ToastComponent,
    DropdownProfileMenuComponent,
    NavbarLogosSectionComponent,
    WalletNavComponent,
    StatisticCardComponent,
    ClarityModule.forRoot({
      enabled: !!environment.analytics?.clarityProjectId,
      projectId: environment.analytics?.clarityProjectId,
    }),
    NgxGoogleAnalyticsModule.forRoot(
      environment.analytics?.googleAnalyticsTrackingCode
    ),
    NgxGoogleAnalyticsRouterModule,
    RippleDirective,
    FeatureFlagDirective,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializeService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaptchaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
