import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { WalletService } from "@services/wallet.service";
import { UserWallet, WalletsData } from "@interfaces/wallet";
import { User, UserData } from "@interfaces/user";
import { AuthService } from "@services/auth/auth.service";
import { SubscriptionMenuComponent } from "@components/subscription-menu/subscription-menu.component";
import { DialogService } from "@ngneat/dialog";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-wallet-nav",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./wallet-nav.component.html",
  styleUrls: ["./wallet-nav.component.scss"],
})
export class WalletNavComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);

  wallets: UserWallet[] = [];
  user!: User | undefined;

  isWalletsLoaded = false;

  freeWallet: any = null;
  personalWallet: any = {};
  subscriptionWallet: any = null;
  constructor(
    private walletService: WalletService,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.currentUser$.subscribe((userData: UserData | null) => {
      this.user = userData?.user;
    });
  }

  getWallets() {
    this.walletService.getUserWallets().subscribe((resp: WalletsData) => {
      this.isWalletsLoaded = true;

      if (resp.DEFAULT) {
        this.personalWallet = {
          type: "personal",
          balance: resp.DEFAULT.balance,
        };
      }

      if (resp.FREE) {
        this.freeWallet = {
          type: "fidelity",
          balance: resp.FREE.balance,
        };
      }

      if (resp.SUBSCRIPTION) {
        this.subscriptionWallet = {
          type: "subscription",
          balance: resp.SUBSCRIPTION.balance,
        };
      }
    });
  }

  showSubscriptionMenu() {
    if (environment.settings.disablePurchases) {
      return;
    }
    const dialogRef = this.dialog.open(SubscriptionMenuComponent, {
      width: "300px",
      windowClass: "navigationMenuPopup",
    });

    dialogRef.afterClosed$.subscribe((result) => {
      if (result) {
        this.router.navigate(["/subscribe"]);
      }
    });
  }

  ngOnInit(): void {
    this.getWallets();
  }
}
