import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipDirective } from "@directives/tooltip.directive";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "app-statistic-card",
  standalone: true,
  imports: [CommonModule, TooltipDirective, AngularSvgIconModule],
  templateUrl: "./statistic-card.component.html",
  styleUrls: ["./statistic-card.component.scss"],
})
export class StatisticCardComponent {
  @Input() title!: string;
  @Input() value!: number;
  @Input() hexagonColor!: string;
  @Input() patternId!: string;
  @Input() gradientId!: string;
  @Input() tooltipItems = [];

  hovered = false;
}
