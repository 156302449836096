import { Injectable } from "@angular/core";
import { ToastMessageService } from "./toast-message.service";

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  constructor(private readonly toastService: ToastMessageService) {}
  modulesAccess = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: false,
    hiringAccess: false,
    classroomAccess: false,
  };

  getModulesAccess(user: any) {
    if (!user.company) {
      this.modulesAccess.cyberawarenessAccess = false;
      if (user.roles.includes("cyber-hirer")) {
        this.modulesAccess.hiringAccess = true;
      }
      return this.modulesAccess;
    }

    const settings = user.company.settings;
    let classroomAccess = false;
    const subscription = user.subscription;
    if (subscription) {
      const features = JSON.parse(subscription.features);
      if (features.calssroom_active) {
        classroomAccess = true;
      }
    }

    this.modulesAccess = {
      cyberlearnAccess: settings.cyberlearn_access,
      cyberlabAccess: settings.cyberlab_access,
      eventAccess: settings.cyberevent_access,
      hiringAccess: user.roles.includes("cyber-hirer")
        ? true
        : settings.cyber_hiring_access,
      cyberawarenessAccess: settings.cyber_awareness_access,
      certificationAccess: settings.cyber_certification_access,
      classroomAccess: classroomAccess,
    };

    return this.modulesAccess;
  }

  async copyToClipboard(textToCopy: string) {
    try {
      const tmpInput = document.createElement("input");
      tmpInput.setAttribute("value", textToCopy);
      document.body.appendChild(tmpInput);
      tmpInput.select();
      await navigator.clipboard.writeText(textToCopy);
      document.body.removeChild(tmpInput);
      this.toastService.initiate({
        toastClass: "success-toast",
        toastTitle: $localize`:@@copiedToClipboard:Copied to clipboard`,
        iconPath: "/assets/icons/copy.svg",
        show: true,
      });
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
  }
}
