import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { environment } from "@environments/environment";

@Component({
  selector: "app-navigation-menu",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./navigation-menu.component.html",
  styleUrls: ["./navigation-menu.component.scss"],
})
export class NavigationMenuComponent {
  readonly document = document;
  readonly environment = environment;
  readonly window = window;
  cyberlabLink = environment.frontend.cyberlab;
  cyberLearnLink = environment.frontend.cyberlearn;
  discordLink = environment.websites.discord;
}
