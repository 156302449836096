import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RouterModule } from "@angular/router";
import { RippleDirective } from "@directives/ripple.directive";

@Component({
  selector: "app-tabs",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RouterModule, RippleDirective],
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent {
  @Input() tabsArray!: any[];
  @Input() position!: string;
  @Input() customTabTextClass!: string;
  @Input() activatedTab = { index: 0 } as any; // Fix me to interface

  @Output() TabChanged = new EventEmitter<number>();

  setTab(data: any, index: number) {
    this.activatedTab = { index, data };
    this.TabChanged.emit(this.activatedTab);
  }
}
