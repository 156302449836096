import { CommonModule } from "@angular/common";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services/auth/auth.service";

@Component({
  selector: "app-account-not-active",
  templateUrl: "./account-not-active.component.html",
  styleUrls: ["./account-not-active.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class AccountNotActiveComponent implements OnInit, OnDestroy {
  @ViewChild("matrix", { static: true }) matrix!: ElementRef<HTMLCanvasElement>;
  canvas!: HTMLCanvasElement;
  ctx!: any;
  animationIntervalRef!: any;
  columns!: number;
  fontSize = 10;
  drops: number[] = [];
  english = "0123456789ABCDEF";
  englishArray!: string[];
  requestId!: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    this.authService.currentUser$.subscribe((userData) => {
      if (userData) {
        const user = userData.user;
        let shouldNavigateHome = false;
        if (
          user.company_id &&
          user.company_confirmation_status === "approved"
        ) {
          shouldNavigateHome = true;
        }
        if (!user.is_disabled) {
          shouldNavigateHome = true;
        }
        shouldNavigateHome = false;

        shouldNavigateHome = false;
        if (user.confirmed) {
          shouldNavigateHome = true;
        }

        if (shouldNavigateHome) {
          this.router.navigate(["/"]);
        }
      }
    });
  }
  title = "";
  message = "";
  ngOnInit(): void {
    this.canvas = this.matrix.nativeElement;
    this.ctx = this.canvas.getContext("2d");
    this.columns = 250;

    this.canvas ? (this.canvas.width = window.innerWidth) : null;
    this.canvas ? (this.canvas.height = window.innerHeight) : null;

    this.englishArray = this.english.split("");

    for (let x = 0; x < this.columns; x++) {
      this.drops[x] = Math.random() * this.canvas.height;
    }
    this.draw();
    this.animationIntervalRef = setInterval(this.draw, 38);

    this.activatedRoute.paramMap.subscribe((params) => {
      const error = params.get("error");
      switch (error) {
        case "company-not-confirmed":
          this.title = $localize`:@@accountNotActive.companyNotConfirmed:Approval pending for the account`;
          this.message = $localize`:@@accountNotActive.contactManager:Contact your organization's manager for approval`;
          break;
        case "company-rejected":
          this.title = $localize`:@@accountNotActive.companyRejected:Account removed from organization`;
          this.message = $localize`:@@accountNotActive.contactManagerRejected:Contact your organization's manager for more information`;
          break;
        case "account-disabled":
          this.title = $localize`:@@accountNotActive.accountDisabled:Account disabled"`;
          this.message = $localize`:@@accountNotActive.contactSupport:Please contact support if you think this is a mistake`;
          break;
        case "account-not-confirmed":
          this.title = $localize`:@@accountNotActive.unverifiedEmail:Unverified email address`;
          this.message = $localize`:@@accountNotActive.checkEmail:Please check your email for a verification link`;
          break;
        default:
          this.router.navigate(["/404"]);
          break;
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.animationIntervalRef);
  }

  goToLoginPage() {
    localStorage.removeItem("currentUser");
    this.authService.publishNullUser();
    this.router.navigate(["/login"]);
  }

  draw = () => {
    this.ctx ? (this.ctx.fillStyle = "rgba(0,0,0,0.05)") : null;
    this.ctx
      ? this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      : null;

    this.ctx ? (this.ctx.fillStyle = "#0da59e") : null;
    this.ctx ? (this.ctx.font = this.fontSize + "px Chakra Petch") : null;

    for (let i = 0; i < this.drops.length; i++) {
      const text =
        this.english[Math.floor(Math.random() * this.englishArray.length)];
      this.ctx
        ? this.ctx.fillText(
            text,
            i * this.fontSize,
            this.drops[i] * this.fontSize
          )
        : null;

      if (
        this.drops[i] * this.fontSize > this.canvas.height &&
        Math.random() > 0.975
      )
        this.drops[i] = 0;

      //increment y coordinate
      this.drops[i]++;
    }
    this.requestId = requestAnimationFrame(() => this.draw);
  };
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.setNewCanvasDimenstions();
  }
  setNewCanvasDimenstions() {
    this.canvas ? (this.canvas.width = window.innerWidth) : null;
    this.canvas ? (this.canvas.height = window.innerHeight) : null;
  }
}
