import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface ToastData {
  toastClass: string;
  toastDescription?: string;
  toastTitle: string;
  iconPath?: string;
  show: boolean;
  time?: number;
}

@Injectable({
  providedIn: "root",
})
export class ToastMessageService {
  data: ToastData = {} as ToastData;
  public open = new BehaviorSubject<ToastData>({} as ToastData);

  initiate(data: ToastData) {
    this.data = { ...data, show: true };
    this.open.next(data);
  }

  showSuccess(message: string, description = "", time = 3000) {
    this.initiate({
      toastClass: "success-toast",
      toastTitle: message,
      toastDescription: description,
      iconPath: "assets/icons/check.svg",
      time,
      show: true,
    });
  }

  showError(message: string) {
    this.initiate({
      toastClass: "danger-toast",
      toastTitle: message,
      iconPath: "assets/icons/error.svg",
      show: true,
    });
  }
  showWarning(data: { message: string; time: number }) {
    this.initiate({
      toastClass: "warning-toast",
      toastTitle: data.message,
      iconPath: "assets/icons/warning-2.svg",
      show: true,
      time: 5000,
    });
  }

  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
  }
}
