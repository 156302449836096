const env = (window as any)["env"];

export const environment: any = {
  name: "prod",
  production: true,
  settings: {
    appName: "SecDojo",
    emulatorAllowed: false,
    debuging: false,
    autoLogout: true,
    recaptchaSiteKey: "6LfwKOcnAAAAAH8QkuF_hD-7NRh8PbGZkbZOXTfY", // Recaptcha Key
    recaptchaSiteKeyV2: "6LevbXIoAAAAAC1Ic326lpnKCYaA6Ew-iU1wgSzU", // Recaptcha Key
    isSecDojo: true,
    logoPath: "assets/icons/Logo.svg",
    logoMobilePath: "assets/icons/logo-mobile.svg",
    socialProviders: ["google", "github", "discord", "microsoft"],
    disableSocials: false,
    disabledSignUp: false,
    disabledOrganizationSignUp: false,
    disablePasswordReset: false,
    disablePurchases: false,
    disableMailing: false,
  },
  websites: {
    discord: "https://discord.gg/hwJ3aeKdQU",
  },
  analytics: {
    mixPanelToken: "bdd023eda5929e6ea8f075a75bfe433b", // Mixpanel Token //bdd023eda5929e6ea8f075a75bfe433b
    clarityProjectId: "exo794qq54", // Clarity Project ID
    googleAnalyticsTrackingCode: "G-ZS24W95E9R", // Google Analytics Tracking Code
  },
  monitoring: {
    // Data Source Name. In dev and preprod leave empty
    sentryDSN: "https://6476f59cff47e6c2518b95348e9008c4@sentry.sec-dojo.com/2",

    // Trace Propagation Targets
    // In dev and preprod leave empty
    // In production set to backend URL regexes
    // For example, for Account: [/^https:\/\/bo-account\.sec-dojo\.com/]
    tracePropagationTargets: [/^https:\/\/bo-account\.sec-dojo\.com/],

    // In dev and preprod leave empty. In production set to "saas-production"
    environment: "saas-production",
  },
  frontend: {
    account: "https://account.sec-dojo.com",
    cyberlab: "https://cyberlab.sec-dojo.com",
    cyberlearn: "https://cyberlearn.sec-dojo.com",
    cyberwar: "https://cyberwar.sec-dojo.com",
    secdojo: "https://www.sec-dojo.com",
    support: "https://support.sec-dojo.com",
    cyberawareness: "https://cyber-awareness.sec-dojo.com",
  },
  backend: {
    account: "https://bo-account.sec-dojo.com",
    cyberlab: "https://bo-cyberlab.sec-dojo.com",
  },
  getBackendUrl: function () {
    return this.backend.account + "/api";
  },
};

if (env) {
  // Override config options if they exist in the environment
  for (const key in env) {
    if (Object.prototype.hasOwnProperty.call(env, key)) {
      environment[key] = env[key];
    }
  }
}
