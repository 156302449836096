<ngx-invisible-recaptcha
  *ngIf="siteKey"
  class="opacity-0"
  #captchaElem
  [siteKey]="siteKey"
  (load)="handleCaptcha()"
  (success)="handleSuccess($event)"
  [useGlobalDomain]="false"
  type="image"
  badge="bottomright"
>
</ngx-invisible-recaptcha>
