import { Injectable } from "@angular/core";

import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlingService {
  readonly environment = environment;

  /**
   * A debugging helper function
   * @param error Error message
   * @param mName Method Name
   * @param fName File Name
   * @param type File type ( component service directive..)
   */
  debug(
    error: any,
    mName: string,
    fName: string,
    type?: "s" | "c" | "d"
  ): void {
    if (environment.settings.debuging) {
      let ext = "";
      switch (type) {
        case "s":
          ext = ".service.ts";
          break;
        case "c":
          ext = ".component.ts";
          break;
        case "d":
          ext = ".directive.ts";
          break;
        default:
          break;
      }
      console.log(
        "%c----- Debugging message -----\nMethod: " +
          mName +
          "\nFile: " +
          fName +
          ext +
          "\nError: " +
          JSON.stringify(error, null, "\t"),
        "color:red"
      );
    }
  }
}
