import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RedirectionService {
  redirectionSubj = new Subject<any>();
  redirection$ = this.redirectionSubj.asObservable();
}
