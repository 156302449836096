import { Injectable } from "@angular/core";
import mixpanel from "mixpanel-browser";
import { environment } from "@environments/environment";
import { flatten } from "flat";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  user!: any;

  constructor() {
    this.init();
  }

  init(): void {
    if (environment.analytics.mixPanelToken) {
      mixpanel.init(environment.analytics.mixPanelToken, {
        debug: true,
      });
    } else {
      console.warn("Mixpanel is disabled: no token found.");
    }
  }

  identifyUser(user: any) {
    if (environment.analytics.mixPanelToken) {
      if (user) {
        console.log;
        const mxPanelUser = structuredClone(user);
        delete mxPanelUser["firstname"];
        delete mxPanelUser["lastname"];
        delete mxPanelUser["name"];
        delete mxPanelUser["email"];
        mxPanelUser["$firstname"] = user.firstname;
        mxPanelUser["$lastname"] = user.lastname;
        mxPanelUser["$name"] = user.name;
        mxPanelUser["$email"] = user.email;
        mixpanel.identify(mxPanelUser.id.toString());
        mixpanel.people.set(flatten(mxPanelUser));
        // mixpanel.people.set(flatten(mxPanelUser));
      } else {
        mixpanel.identify(mixpanel.get_distinct_id());
      }
    } else {
      console.warn("Mixpanel is disabled: no token found.");
    }
  }

  // This is a simple wrapper around mixpanel.track()
  // so we can call it from any component and service where we need to track the user’s actions.
  track(id: string, action: any = {}): void {
    if (!environment.analytics.mixPanelToken) return;
    mixpanel.track(id, action);
  }
}
