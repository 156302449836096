import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { EMPTY, Observable } from "rxjs";
import { RolesService } from "../roles.service";

@Injectable({
  providedIn: "root",
})
export class DefaultRouteService implements CanActivate {
  constructor(
    private readonly rolesService: RolesService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.rolesService.currentUserRoles$.subscribe((rolesArray: string[]) => {
      if (rolesArray) {
        const roles = rolesArray;
        if (roles.includes("company-manager")) {
          this.router.navigateByUrl("/company");
        } else if (roles.includes("company-member") || roles.includes("user")) {
          this.router.navigateByUrl("/home");
        } else if (roles.includes("super-admin")) {
          this.router.navigateByUrl("/profile");
        } else {
          this.router.navigateByUrl("/login");
        }
      }
    });
    return false;
  }
}
