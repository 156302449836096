import { Component, inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogRef } from "@ngneat/dialog";
import { AngularSvgIconModule } from "angular-svg-icon";
import { User } from "@interfaces/user";
import { ProfileService } from "@services/profile.service";
import { AuthService } from "@services/auth/auth.service";
import { OnboardingModalContent } from "@interfaces/onboarding";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
@UntilDestroy({ checkProperties: true })
@Component({
  selector: "app-onboarding-modal",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./onboarding-modal.component.html",
  styleUrls: ["./onboarding-modal.component.scss"],
})
export class OnboardingModalComponent implements OnInit {
  ref: DialogRef<any> = inject(DialogRef);
  user!: User | null;
  stepsLimit!: number;
  content!: OnboardingModalContent[];
  activeStep = 1;
  updateOnboarding!: Subscription;
  type = "firstLogin";
  constructor(private readonly userService: ProfileService) {}

  ngOnInit() {
    this.user = this.ref.data.user;
    this.type = this.ref.data.type;
    this.content = this.ref.data.content;
  }

  nextStep() {
    this.activeStep += 1;
    if (this.activeStep - 1 === this.content.length) {
      this.ref.close(true);
    }
  }
  goToStep(step: number) {
    this.activeStep = step;
  }
  previousStep() {
    this.activeStep--;
  }
}
