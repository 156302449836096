import { Injectable, NgZone } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class CaptchaInterceptor implements HttpInterceptor {
  readonly window: any = window;

  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    protected zone: NgZone
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has("X-Captcha") && environment.production) {
      const token = localStorage.getItem("recaptcha");

      if (token) {
        request = request.clone({
          setHeaders: {
            "X-Captcha": token,
          },
        });

        localStorage.removeItem("recaptcha");
      }
    }
    return next.handle(request);
  }
}
