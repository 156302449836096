import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RippleDirective } from "@directives/ripple.directive";
import { environment } from "@environments/environment";
import { DialogRef } from "@ngneat/dialog";
import { AuthService } from "@services/auth/auth.service";

@Component({
  selector: "app-subscription-menu",
  templateUrl: "./subscription-menu.component.html",
  styleUrls: ["./subscription-menu.component.scss"],
  standalone: true,
  imports: [CommonModule, RippleDirective, RouterModule],
})
export class SubscriptionMenuComponent implements OnInit {
  ref: DialogRef<any> = inject(DialogRef);
  appName = environment.settings.appName;
  planText = `Level up your Cyber skills with ${this.appName}!`;

  subscription: any;
  isSubscribed = false;
  buttonText = "Upgrade Now!";
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((userData: any) => {
      if (userData) {
        this.subscription = userData.user.subscription;
        if (this.subscription) {
          this.isSubscribed = true;
          this.planText =
            "You are subscribed to the " +
            this.subscription.plan +
            " Plan. Enjoy your training!";
          this.buttonText = "View Plan";
        }
      }
    });
  }

  upgrade() {
    this.ref.close(true);
  }
}
