import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { catchError, map, Observable, throwError } from "rxjs";
import { ErrorHandlingService } from "@services/error-handling.service";
import {
  CheckoutInfo,
  CheckoutComplete,
  ChargeWalletPayload,
  WalletsResponse,
  WalletsData,
  TransactionData,
  WalletStats,
  SpendingStats,
  SpendingHistory,
} from "@interfaces/wallet";
import { GenericResponse } from "@interfaces/generic-resp";
import { CustomResponse, PaginationResp } from "@interfaces/pagination";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  constructor(private http: HttpClient, private ehs: ErrorHandlingService) {}

  getUserWallets(): Observable<WalletsData> {
    return this.http
      .get<WalletsResponse>(`${environment.backend.account}/api/wallet`)
      .pipe(
        map((resp: WalletsResponse) => resp.data),
        catchError((error: any) => {
          this.ehs.debug(error, "getUserWallets", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }
  checkout(data: CheckoutInfo): Observable<any> {
    return this.http
      .post(`${environment.backend.account}/api/checkout`, data)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "checkout", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  checkoutComplete(data: CheckoutComplete): Observable<any> {
    return this.http
      .post(`${environment.backend.account}/api/checkout/complete`, data)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "checkoutComplete", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  getCheckoutsHistory(
    sortParams: any,
    page: number
  ): Observable<PaginationResp<SpendingHistory[]>> {
    return this.http
      .get<GenericResponse<PaginationResp<SpendingHistory[]>>>(
        `${environment.backend.account}/api/v2/transactions/history?page=${page}`
      )
      .pipe(
        map(
          (response: GenericResponse<PaginationResp<SpendingHistory[]>>) =>
            response.data
        ),
        catchError((error: any) => {
          this.ehs.debug(error, "checkoutComplete", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  transferCoinsToGroupWallet(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.backend.account}/api/checkout/transferCoinsToGroup`,
        data
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "checkoutComplete", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  transferCoinsToCompanyMembers(
    data: ChargeWalletPayload
  ): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/checkout/transferCoinsToCompanyMembers`,
        data
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "transferCoinsToCompanyMembers", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  getCompanyWalletStats(): Observable<WalletStats> {
    return this.http
      .get<GenericResponse<WalletStats>>(
        `${environment.backend.account}/api/v2/company/wallet/stats`
      )
      .pipe(
        map((resp: GenericResponse<WalletStats>) => resp.data),
        catchError((error: any) => {
          this.ehs.debug(error, "getCompanySepndingStats", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  getCompanySepndingStats(
    period: string | null,
    type = "all"
  ): Observable<SpendingStats> {
    let url = `${environment.backend.account}/api/v2/company/spending/details?type=${type}`;
    if (period) {
      url = `${environment.backend.account}/api/v2/company/spending/details?period=${period}&type=${type}`;
    }
    return this.http.get<GenericResponse<SpendingStats>>(url).pipe(
      map((resp: GenericResponse<SpendingStats>) => resp.data),
      catchError((error: any) => {
        this.ehs.debug(error, "getCompanySepndingStats", "wallet", "s");
        return throwError(() => new Error(error));
      })
    );
  }
  getCompanySpendingHistory(
    perPage: number | null,
    page: number,
    type = "all"
  ): Observable<PaginationResp<SpendingHistory[]>> {
    let url = `${environment.backend.account}/api/v2/company/spending/history`;
    if (page) {
      url = `${url}?page=${page}`;
    }
    if (perPage) {
      url = `${url}&perPage=${perPage}`;
    }
    if (type) {
      url = `${url}&type=${type}`;
    }
    return this.http
      .get<GenericResponse<PaginationResp<SpendingHistory[]>>>(url)
      .pipe(
        map(
          (resp: GenericResponse<PaginationResp<SpendingHistory[]>>) =>
            resp.data
        ),
        catchError((error: any) => {
          this.ehs.debug(error, "getCompanySpendingHistory", "wallet", "s");
          return throwError(() => new Error(error));
        })
      );
  }
}
