import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ToastMessageService } from "@services/toast-message.service";

@Component({
  selector: "app-toast",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  animations: [
    trigger("toastTrigger", [
      // This refers to the @trigger we created in the template
      state(
        "open",
        style({
          transform: "translateX(0%)",
          display: "inline-block",
          opacity: 1,
          visibility: "visible",
        })
      ), // This is how the 'open' state is styled
      state(
        "close",
        style({
          display: "none",
          transform: "translateX(200%)",
          opacity: 0,
          visibility: "hidden",
        })
      ), // This is how the 'close' state is styled
      transition("open <=> close", [
        // This is how they're expected to transition from one to the other
        animate("300ms ease-in-out"),
      ]),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  toastClass!: string; // Class lists can be added as an array
  iconPath: string | undefined = "";
  toastTitle = "";
  toastDescription: string | undefined = "";
  showsToast = false; // This is what toggles the component to show or hide

  constructor(private toastService: ToastMessageService) {}

  ngOnInit(): void {
    this.toastService.open.subscribe((data) => {
      this.showsToast = data.show;
      this.toastClass = data.toastClass;
      this.toastTitle = data.toastTitle;
      this.iconPath = data.iconPath;
      this.toastDescription = data.toastDescription;
      if (data.show) {
        setTimeout(() => {
          this.toastService.hide();
        }, data.time || 3000);
      }
    });
  }
  closeToast() {
    this.showsToast = !this.showsToast;
  }
}
