import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { GenericResp } from "@interfaces/generic-resp";
import { GeneralNotif } from "@interfaces/notification";
import { map, pipe, Subject } from "rxjs";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  notificationSubject = new Subject<number>();
  notification$ = this.notificationSubject.asObservable();

  private readonly backendApi = environment.backend.account;

  constructor(private readonly http: HttpClient) {}

  getSpecialNotifications(
    read: null | boolean,
    page: number
  ): Observable<GenericResp> {
    return this.http.get<GenericResp>(
      `${this.backendApi}/api/notifications?perPage=10&read=${read}&page=${page}`,
      {
        headers: {
          ignoreLoadingInterceptor: "true", // Skip HTTP Loading Interceptor
          ignoreError: "true",
        },
      }
    );
  }

  readBulkNotification(ids: any): Observable<GenericResp> {
    return this.http.post<GenericResp>(
      `${this.backendApi}/api/notifications/read`,
      ids,
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  readNotification(id: number): Observable<GenericResp> {
    return this.http.post<GenericResp>(
      `${this.backendApi}/api/notifications/read`,
      {
        ids: [id],
      },
      { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
    );
  }

  updateLastNotificationCheck(): Observable<GenericResp> {
    return this.http.post<GenericResp>(
      `${this.backendApi}/api/notifications/last-check`,
      {},
      { headers: { ignoreLoadingInterceptor: "true", ignoreError: "true" } } // Skip HTTP Loading Interceptor
    );
  }
}
