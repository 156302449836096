import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagsService {
  public backendApi = environment.backend.account;

  private featuresSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public features: Observable<any> = this.featuresSubject.asObservable();

  constructor(public http: HttpClient) {}

  public loadFeatureFlags(type: "authenticated" | "public" = "authenticated") {
    return this.http
      .get(`${this.backendApi}/api/feature-flags?type=${type}`, {
        headers: { ignoreError: "true" },
      })
      .subscribe({
        next: (data: any) => {
          this.featuresSubject.next(data.data);
        },
        error: (error) => {
          console.error("Failed to fetch feature flags", error);
        },
      });
  }

  getFeatureFlags(): Observable<any> {
    return this.features; // This allows components to subscribe to the feature flags
  }
}
