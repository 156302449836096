import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { DeviceService } from "./device.service";

@Injectable({
  providedIn: "root",
})
export class MobileRedirectGuardService {
  constructor(private router: Router, private deviceService: DeviceService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.deviceService.isMobile()) {
      this.router.navigate(["/mobile-redirect"]); // Redirect to the mobile page
      return false; // Prevent access to the requested route
    }
    return true; // Allow access to the requested route
  }
}
