import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "@environments/environment";
import { FeatureFlagsService } from "./feature-flags.service";

@Injectable({
  providedIn: "root",
})
export class AppInitializeService {
  constructor(private titleService: Title) {}
  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      const baseUrl = window.location.origin;
      const envScript = document.createElement("script");
      envScript.src = `${baseUrl}/env.js`;
      envScript.async = false;

      envScript.onload = () => {
        console.log("env.js loaded successfully.");
        this.overrideEnvironment((window as any).env);
        this.titleService.setTitle("Account - " + environment.settings.appName);
        resolve();
      };

      envScript.onerror = () => {
        console.error("env.js not found. Running with default configuration.");
        this.titleService.setTitle("Account - " + environment.settings.appName);
        resolve();
      };

      document.head.appendChild(envScript);
    });
  }

  private overrideEnvironment(env: any): void {
    if (env) {
      for (const key in env) {
        if (Object.prototype.hasOwnProperty.call(env, key)) {
          environment[key] = env[key];
        }
      }
    }
  }
}
