import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { environment } from "@environments/environment";
import { RolesService } from "@services/roles.service";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn) {
      return true;
    }
    if (environment.settings.autoLogout) {
      this.authService.logout("login");
    }
    return true;
  }
}

@Injectable({
  providedIn: "root",
})
export class UnauthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private readonly rolesService: RolesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isLoggedIn) {
      return true;
    }
    const routeToRedirect = this.redirectToHome();

    return routeToRedirect;
  }

  redirectToHome = async () => {
    const roles = await this.rolesService.rolesPromise();
    if (roles.includes("company-manager")) {
      return this.router.navigateByUrl("/company");
    } else if (
      roles.includes("company-member") ||
      roles.includes("user") ||
      roles.includes("super-admin")
    ) {
      return this.router.navigateByUrl("/home");
    } else {
      return this.router.navigateByUrl("/login");
    }
  };
}
