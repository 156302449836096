import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { JwtService } from "@services/jwt.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class JwtInterceptor implements HttpInterceptor {
  private jwtToken!: string | null;
  constructor(private jwtService: JwtService, private router: Router) {
    this.jwtService.jwtSubj.subscribe((token: string) => {
      if (!token) {
        const currentUserData = JSON.parse(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          localStorage.getItem("currentUser")!
        );
        if (!currentUserData) {
          return;
        }

        this.jwtToken = currentUserData.token;
      } else {
        this.jwtToken = token;
      }
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // FIXME: Set appropriate interfaces

    // Add authorization header with jwt token if available
    if (this.jwtToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
