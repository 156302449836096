import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import {
  CompanylanguageData,
  Language,
  LanguagesData,
  PreferredlanguageData,
  UserlanguageData,
} from "@interfaces/editProfile";
import { GenericResp, GenericResponse } from "@interfaces/generic-resp";
import { catchError, map, Observable, throwError } from "rxjs";
import { ErrorHandlingService } from "./error-handling.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient, private ehs: ErrorHandlingService) {}

  changeAvatar(data: any) {
    return this.http
      .post(`${environment.backend.account}/api/user/profile/avatar`, data)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "changeAvatar", "profile", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  updateOnboarding(): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/onboarding/first-login`,
        {}
      )
      .pipe(
        catchError((error: any) => {
          return throwError(() => new Error(error));
        })
      );
  }
  updateSubscriptionOnboarding(): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/subscription/complete`,
        {}
      )
      .pipe(
        catchError((error: any) => {
          return throwError(() => new Error(error));
        })
      );
  }
  resetOnboardingProcess(): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/onboarding/reset`,
        {}
      )
      .pipe(
        catchError((error: any) => {
          return throwError(() => new Error(error));
        })
      );
  }

  getLanguages(): Observable<Language[]> {
    return this.http
      .get<LanguagesData>(`${environment.backend.account}/api/languages`)
      .pipe(map((resp: LanguagesData) => resp.data));
  }

  getUserLanguages(): Observable<UserlanguageData> {
    return this.http
      .get<UserlanguageData>(
        `${environment.backend.account}/api/v2/languages/user`
      )
      .pipe(map((resp: UserlanguageData) => resp.data));
  }

  getCompanyLanguages(): Observable<CompanylanguageData> {
    return this.http
      .get<CompanylanguageData>(
        `${environment.backend.account}/api/v2/languages/company`
      )
      .pipe(map((resp: CompanylanguageData) => resp.data));
  }

  getUserPreferredLanguage(): Observable<Language> {
    return this.http
      .get<PreferredlanguageData>(
        `${environment.backend.account}/api/languages/user/PreferredLanguage`
      )
      .pipe(map((resp: PreferredlanguageData) => resp.data));
  }
  getCompanyPreferredLanguage(): Observable<Language> {
    return this.http
      .get<PreferredlanguageData>(
        `${environment.backend.account}/api/languages/company/PreferredLanguage`
      )
      .pipe(map((resp: PreferredlanguageData) => resp.data));
  }

  setUserPreferredLanguage(languageId: number): Observable<any> {
    return this.http.post<GenericResp>(
      `${environment.backend.account}/api/languages/user/setPreferredLanguage`,
      { language_id: languageId }
    );
  }

  updateCompanySettings(data: {
    visibility: "public" | "private";
    language_id: number;
    "2SV_active"?: boolean;
  }) {
    return this.http.post<GenericResp>(
      `${environment.backend.account}/api/company/settings/update`,
      data
    );
  }

  getCompanySettings() {
    return this.http.get<GenericResp>(
      `${environment.backend.account}/api/company/settings`
    );
  }

  setCompanyPreferredLanguage(languageId: number): Observable<any> {
    return this.http.post<PreferredlanguageData>(
      `${environment.backend.account}/api/languages/company/setPreferredLanguage`,
      { language_id: languageId }
    );
  }

  getAllAvatars() {
    return this.http.get(`${environment.backend.account}/api/avatar`);
  }

  unlinkSocialAccount(provider: string) {
    return this.http.post(
      `${environment.backend.account}/api/social/${provider}/unlink`,
      {}
    );
  }

  getAllUserAvatars(): Observable<number[]> {
    return this.http
      .get<any>(`${environment.backend.account}/api/avatar`)
      .pipe(map((resp: any) => resp.data));
  }

  getGroupAvatars(): Observable<number[]> {
    return this.http
      .get(`${environment.backend.account}/api/groupAvatars`)
      .pipe(map((resp: any) => resp.data));
  }

  switchCompany(companyId: number) {
    return this.http.post(
      `${environment.backend.account}/api/auth/manager/change-company`,
      { company_id: companyId }
    );
  }
}
