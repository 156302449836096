import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { User } from "@interfaces/user";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { environment } from "@environments/environment";
import { ProfileService } from "@services/profile.service";
import { Language, UserlanguageData } from "@interfaces/editProfile";
import { AuthService } from "@services/auth/auth.service";
import { UtilitiesService } from "@services/utilities.service";
import { Company } from "@interfaces/company";
import { DialogService } from "@ngneat/dialog";
import { ConfirmActionModalComponent } from "@components/modals/confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-dropdown-profile-menu",
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RouterModule],
  templateUrl: "./dropdown-profile-menu.component.html",
  styleUrls: ["./dropdown-profile-menu.component.scss"],
})
export class DropdownProfileMenuComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);
  @Input() user!: User | null;
  @ViewChild("dropdownContent") dropdownContent!: ElementRef;
  isDropdownOpen = false;
  readonly document = document;
  backendAccount = environment.backend.account;
  readonly environment = environment;
  readonly window = window;
  cyberlabLink = environment.frontend.cyberlab;
  cyberLearnLink = environment.frontend.cyberlearn;
  cyberawarenessLink = environment.frontend.cyberawareness;
  discordLink = environment.websites.discord;
  isEditingLanguage = false;

  languagesAvailable: any = [];
  preferredLanguage: Language = {} as Language;
  isPreferredLanguageLoaded = false;
  oldPreferenceLanguageId = 0;
  selectedLanguage = 0;
  isLanguagesLoaded = false;
  switchingCompany = false;

  constructor(
    private readonly profileService: ProfileService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly utilitiesService: UtilitiesService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getUser();
    // this.getLanguages();
    this.getUserLanguages();
  }

  modulesAccess = {
    cyberlearnAccess: true,
    cyberlabAccess: true,
    eventAccess: true,
    cyberawarenessAccess: false,
    certificationAccess: false,
    hiringAccess: false,
  };

  linkClick($event: any, shouldGo: boolean) {
    if (!shouldGo) {
      $event.preventDefault();
    }
  }
  getUser() {
    this.authService.currentUser$.subscribe((userData) => {
      if (userData) {
        const user = userData.user;
        if (user.managed_companies) {
          const managedOrganization: any = user.managed_companies.filter(
            (company: any) => company.current
          );

          if (managedOrganization.length > 0) {
            user.company = managedOrganization[0];
            user.company_id = managedOrganization[0].id;
          }
        }

        this.modulesAccess = this.utilitiesService.getModulesAccess(user);
      }
    });
  }

  switchCompany(company: any) {
    this.profileService.switchCompany(company.id).subscribe(() => {
      window.location.reload();
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  getUserPreferredLanguage() {
    this.profileService.getUserPreferredLanguage().subscribe({
      next: (data: Language) => {
        this.preferredLanguage = data;
        const userLanguage = this.languagesAvailable.find(
          (language: any) => language.id === this.preferredLanguage.id
        );
        userLanguage ? (this.oldPreferenceLanguageId = userLanguage.id) : null;
        userLanguage ? (this.selectedLanguage = userLanguage.id) : null;
        this.isPreferredLanguageLoaded = true;

        if (environment.production) {
          // CHeck if the user is in the right language
          const path = window.location.pathname.split("/").filter((x) => x);
          const url = window.location.pathname.split("/").slice(2).join("/");
          if (userLanguage.code !== path[0]) {
            window.location.href = `${environment.frontend.account}/${userLanguage.code}/${url}`;
          }
        }
      },
    });
  }

  getLanguages() {
    this.profileService.getLanguages().subscribe({
      next: (data: Language[]) => {
        this.languagesAvailable = data;
        this.isLanguagesLoaded = true;
        this.getUserPreferredLanguage();
      },
    });
  }

  getUserLanguages() {
    this.profileService.getUserLanguages().subscribe({
      next: (data: UserlanguageData) => {
        this.languagesAvailable = data.languages;
        this.isLanguagesLoaded = true;

        this.preferredLanguage = data.user_language;
        const userLanguage = this.languagesAvailable.find(
          (language: any) => language.id === this.preferredLanguage.id
        );
        userLanguage ? (this.oldPreferenceLanguageId = userLanguage.id) : null;
        userLanguage ? (this.selectedLanguage = userLanguage.id) : null;
        this.isPreferredLanguageLoaded = true;

        if (environment.production) {
          // CHeck if the user is in the right language
          const path = window.location.pathname.split("/").filter((x) => x);
          const url = window.location.pathname.split("/").slice(2).join("/");
          if (userLanguage.code !== path[0]) {
            window.location.href = `${environment.frontend.account}/${userLanguage.code}/${url}`;
          }
        }
      },
    });
  }

  changeLanguage(code: string) {
    const languageId = this.languagesAvailable.find(
      (language: any) => language.code === code
    ).id;

    this.profileService.setUserPreferredLanguage(languageId).subscribe({
      next: (data: any) => {
        // Get current route:
        if (environment.production) {
          const url = window.location.pathname.split("/").slice(2).join("/");
          window.location.href = `${environment.frontend.account}/${code}/${url}`;
        } else {
          window.location.reload();
        }
      },
    });
  }

  logoutFromBackend() {
    const description = $localize`:@@yourAboutToLogout:You are about to logout from all platforms`;

    const logoutConfirmRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        title: $localize`:@@AreYouSure:Are you sure?`,
        description: description,
        cancelText: $localize`:@@cancel:Cancel`,
        confirmText: $localize`:@@approve:Confirm`,
        neutral: false,
      },
      width: "400px",
    });
    logoutConfirmRef.afterClosed$.subscribe((result: any) => {
      if (result) {
        this.authService.logoutFromBackend().subscribe({
          next: (data: boolean) => {
            console.log("data", data);
            this.router.navigate(["/login"]);
          },
        });
      }
    });
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isButtonClicked =
      clickedElement.classList.contains("dropdown-button");
    if (
      !isButtonClicked &&
      !this.dropdownContent.nativeElement.contains(clickedElement)
    ) {
      this.isDropdownOpen = false;
    }
  }
}
