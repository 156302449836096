import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MainButtonComponent } from "@components/main-button/main-button.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { DialogRef, DialogService } from "@ngneat/dialog";
import { GeneralNotif } from "@interfaces/notification";
import { NotificationsService } from "@services/notifications.service";
import { Subscription } from "rxjs";
import { GenericResp } from "@interfaces/generic-resp";
import { RippleDirective } from "@directives/ripple.directive";
import { Router, RouterModule } from "@angular/router";
import { environment } from "@environments/environment";

@Component({
  selector: "app-notification-popup",
  standalone: true,
  imports: [
    CommonModule,
    MainButtonComponent,
    AngularSvgIconModule,
    RippleDirective,
    RouterModule,
  ],
  templateUrl: "./notification-popup.component.html",
  styleUrls: ["./notification-popup.component.scss"],
})
export class NotificationPopupComponent implements OnInit, AfterViewInit {
  private dialog: DialogService = inject(DialogService);
  ref: DialogRef<any> = inject(DialogRef);
  iconNotifications = {
    LabRequest: "assets/icons/lab.svg",
    LabRequestResponseGroup: "assets/icons/lab.svg",
    LabRequestResponseDedicated: "assets/icons/lab.svg",
    assistanceRequestManager: "assets/icons/chat-bubble.svg",
    assistanceRequestManagerResponse: "assets/icons/chat-bubble.svg",
    assistanceRequestInitiated: "assets/icons/chat-bubble.svg",
    assistanceRequestAdminRangeProvided: "assets/icons/chat-bubble.svg",
    assistanceRequestUserStartProvided: "assets/icons/chat-bubble.svg",
    assistanceRequestChannelCreated: "assets/icons/chat-bubble.svg",
    assistanceRequestFinished: "assets/icons/chat-bubble.svg",
    certificationFirstPartAccomplished: "assets/icons/certificate.svg",
    certificationInterviewStartDateSet: "assets/icons/certificate.svg",
    certificationInterviewStartSoon: "assets/icons/certificate.svg",
    certificationExamPassed: "assets/icons/certificate.svg",
    certificationExamFailed: "assets/icons/certificate.svg",
    certificationInterviewStartDateRescheduled: "assets/icons/certificate.svg",
    CertificationUserChosenDate: "assets/icons/certificate.svg",
    certificationFirstPartFailed: "assets/icons/certificate.svg",
    NewHiringRequestUser: "assets/icons/company-default.svg",
    HiringRequestRejectedHirer: "assets/icons/company-default.svg",
    HirerRequestResponseAdmin: "assets/icons/company-default.svg",
    UserApprovedInterviewDate: "assets/icons/company-default.svg",
    UserApprovedInterviewDateAdmin: "assets/icons/company-default.svg",
    InterviewRescheduledAdmin: "assets/icons/company-default.svg",
    InterviewRescheduleRequest: "assets/icons/company-default.svg",
    NewJobRequest: "assets/icons/company-default.svg",
    JobRequestResponseUser: "assets/icons/briefcase.svg",
    JobRequestResponseAdmin: "assets/icons/company-default.svg",
    HiringRequestCancelledUser: "assets/icons/company-default.svg",
    HiringRequestCancelledAdmin: "assets/icons/company-default.svg",
    UserHired: "assets/icons/company-default.svg",
    UserHiredRecruiter: "assets/icons/company-default.svg",
    UserHiredAdmin: "assets/icons/company-default.svg",
    UserNotHired: "assets/icons/company-default.svg",
    UserNotHiredAdmin: "assets/icons/company-default.svg",
    JobApplicationCancelled: "assets/icons/company-default.svg",
    CertificationRequest: "assets/icons/certificate.svg",
    CertificationRequestResponse: "assets/icons/certificate.svg",
    General: "assets/icons/notification.svg",
    FidelityWalletFill: "assets/icons/wallet.svg",
  };

  activeTab = "unread";
  dissmised = false;
  isNotificationLoaded = true;
  readBulkSubscriptionNotifs!: Subscription;
  readBulkAllLoaded = false;

  hoveredNotif = -1;
  showAcceptRejectButtons = false;
  notificationsRead: any = [];
  notificationsUnRead: any = [];
  readCurrentPage = 1;
  readLastPage = 1;
  unReadCurrentPage = 1;
  unReadLastPage = 1;
  currentPage = 1;
  notifications!: GeneralNotif[];

  isLoading = false;
  notificationNumber = 0;
  constructor(
    private readonly notificationService: NotificationsService,
    private readonly router: Router
  ) {}
  @ViewChild("scrollContainer", { static: true })
  scrollContainerRef!: ElementRef;

  ngAfterViewInit() {
    this.attachScrollListener();
  }

  attachScrollListener() {
    const scrollContainer = this.scrollContainerRef.nativeElement;
    scrollContainer.addEventListener("scroll", this.onScroll.bind(this));
  }

  onScroll(event: Event) {
    const scrollContainer = this.scrollContainerRef.nativeElement;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;
    // Check if the user has reached the bottom of the container.
    const scrollbarDistance = scrollTop + clientHeight;
    if (
      scrollTop + clientHeight >= scrollHeight ||
      scrollHeight - scrollbarDistance <= 1
    ) {
      if (this.dissmised) {
        if (this.readCurrentPage < this.readLastPage) {
          this.readCurrentPage += 1;
          this.getSpecialNotifications();
        }
      } else {
        if (this.unReadCurrentPage < this.unReadLastPage) {
          this.unReadCurrentPage += 1;
          this.getSpecialNotifications();
        }
      }
    }
  }

  mouseEnter(id: number) {
    this.hoveredNotif = id;
  }

  mouseLeave() {
    this.hoveredNotif = -1;
  }

  getReadNotifications() {
    this.isLoading = true;
    this.dissmised = true;
    this.activeTab = "read";
    this.getSpecialNotifications(1);
  }

  getUnReadNotifications() {
    this.isLoading = true;
    this.dissmised = false;
    this.activeTab = "unread";
    this.getSpecialNotifications(1);
  }

  readBulkNotification() {
    this.readBulkAllLoaded = false;
    this.isLoading = true;
    const ids = this.notifications.map((notif) => notif.id);

    this.readBulkSubscriptionNotifs = this.notificationService
      .readBulkNotification({
        ids: ids,
      })
      .subscribe((resp: GenericResp) => {
        for (const notif of this.notificationsUnRead) {
          notif.read = true;
          if (this.isAlreadyFetched(notif, this.notificationsRead)) continue;
          this.notificationsRead.push(notif);
        }
        this.notificationsRead = this.orderNotifications(
          this.notificationsRead
        );
        this.notificationsUnRead = [];
        this.notifications = [];
        this.isLoading = false;
        this.readBulkAllLoaded = true;
        this.getSpecialNotifications(1);
      });
  }

  ngOnInit(): void {
    this.notificationsUnRead = this.ref.data.notifications;
    this.notifications = this.ref.data.notifications;
    this.unReadLastPage = this.ref.data.lastPage;
    this.notificationNumber = this.ref.data.notificationNumber;

    this.notifications = this.notifications.map(
      (notification: GeneralNotif) => {
        notification.iconPath =
          this.iconNotifications[
            notification.type as keyof typeof this.iconNotifications
          ];
        return notification;
      }
    );
  }
  getSpecialNotifications(p?: number) {
    this.isNotificationLoaded = false;
    let page = 1;

    if (p) {
      page = 1;
    } else {
      page = this.dissmised ? this.readCurrentPage : this.unReadCurrentPage;
    }

    this.notificationService
      .getSpecialNotifications(this.dissmised, page)
      .subscribe((resp: any) => {
        if (this.dissmised) {
          this.readLastPage = resp.data.last_page;
          for (const notif of resp.data.data) {
            if (!this.isAlreadyFetched(notif, this.notificationsRead)) {
              this.notificationsRead.push(notif);
            }
          }
          this.notifications = [...this.notificationsRead];
        } else {
          this.unReadLastPage = resp.data.last_page;
          this.notificationNumber = resp.data.total;
          this.notificationService.notificationSubject.next(
            this.notificationNumber
          );
          for (const notif of resp.data.data) {
            if (!this.isAlreadyFetched(notif, this.notificationsUnRead)) {
              this.notificationsUnRead.push(notif);
            }
          }
          this.notifications = [...this.notificationsUnRead];
        }
        this.notifications = this.notifications.map(
          (notification: GeneralNotif) => {
            // Check if notifications.data is a string or an object
            if (typeof notification.data === "string") {
              notification.data = JSON.parse(notification.data);
            }
            if (notification.path) {
              notification.path = this.translateNotificationPath(notification);
            }
            notification.content = this.stringTemplateParser(
              notification.template,
              notification.data
            );
            notification.iconPath =
              this.iconNotifications[
                notification.type as keyof typeof this.iconNotifications
              ];
            return notification;
          }
        );
        this.notifications = [...this.notifications];
        this.isNotificationLoaded = true;
        this.isLoading = false;
      });
  }

  stringTemplateParser(expression: any, valueObj: any) {
    const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
    if (!expression) {
      return "";
    }
    const text = expression.replace(
      templateMatcher,
      (substring: any, value: any, index: any) => {
        value = valueObj[value];
        return value;
      }
    );
    return text;
  }

  readNotification(notification: any) {
    this.isLoading = true;
    this.notificationService
      .readNotification(notification.id)
      .subscribe((resp: any) => {
        notification.read = 1;
        this.notificationsRead.push(notification);
        this.notificationsRead = this.orderNotifications(
          this.notificationsRead
        );

        this.notificationsUnRead = this.notificationsUnRead.filter(
          (notif: any) => notif.id !== notification.id
        );
        this.notifications = [...this.notificationsUnRead];
        this.getSpecialNotifications(1);
        this.isLoading = false;
      });
  }

  translateNotificationPath(notification: any) {
    if (notification.path == "profile_wallet_page") {
      return "/user/wallet";
    }

    return notification.path;
  }

  onLinkClick(event: MouseEvent, notification: any): void {
    // Check if Ctrl/Cmd key is pressed or if the mouse middle button is clicked
    const newTabCondition = event.ctrlKey || event.metaKey || event.which === 2;

    if (notification.path == null) return;

    if (notification.path.includes("https")) {
      if (notification.read == 0) {
        this.readNotification(notification);
      }
      window.open(notification.path, "_blank");
      return;
    }

    if (newTabCondition) {
      event.preventDefault();
      if (notification.read == 0) {
        this.readNotification(notification);
      }

      // Open new tab programmatically after your action
      window.open(
        `${environment.frontend.cyberlab}/${notification.path}`,
        "_blank"
      );
    } else {
      if (notification.read == 0) {
        this.readNotification(notification);
      }

      this.router.navigate([`${notification.path}`]);
    }
  }

  orderNotifications(notifications: any) {
    return notifications.sort((a: any, b: any) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime() > 0
      );
    });
  }

  isAlreadyFetched(notification: any, notifications: any) {
    return notifications.some((notif: any) => notif.id === notification.id);
  }
}
