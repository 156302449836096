import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CompanyDetails, MemberData } from "@interfaces/company";
import { CompanyMember, CompanyMembersResp } from "@interfaces/company-members";
import { GenericResponse } from "@interfaces/generic-resp";
import { CustomResponse, PaginationResp } from "@interfaces/pagination";
import {
  BehaviorSubject,
  catchError,
  concatMap,
  delay,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  throwError,
} from "rxjs";
import { ErrorHandlingService } from "./error-handling.service";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private currentCompanySubj: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  companyDetails$: Observable<any | null>;
  constructor(private http: HttpClient, private ehs: ErrorHandlingService) {
    this.companyDetails$ = this.currentCompanySubj
      .asObservable()
      .pipe(filter((company: any | null) => !!company));
  }
  private currentRequest!: Observable<any> | null;

  searchCompanyMembers(
    query: string,
    take: string
  ): Observable<CompanyMember[]> {
    return this.http
      .post<GenericResponse<CompanyMember[]>>(
        `${environment.backend.account}/api/company/members/search`,
        {
          query,
          take,
        },
        { headers: { ignoreLoadingInterceptor: "true" } } // Skip HTTP Loading Interceptor
      )
      .pipe(
        map((resp: GenericResponse<CompanyMember[]>) => {
          return resp.data;
        })
      );
  }

  getCompanyMembersData(
    data: any,
    page: number
  ): Observable<CustomResponse<PaginationResp<MemberData[]>>> {
    let url = `${environment.backend.account}/api/v2/company/members?page=${page}`;
    url = this.addPaginattionParams(url, data);
    return this.http
      .get<GenericResponse<CustomResponse<PaginationResp<MemberData[]>>>>(url)
      .pipe(
        map(
          (
            resp: GenericResponse<CustomResponse<PaginationResp<MemberData[]>>>
          ) => resp.data
        ),
        catchError((error: any) => {
          this.ehs.debug(error, "getCompanyMemberCM", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  async downloadInviteMembersTemplate() {
    const headers = new HttpHeaders();
    // headers.append("Accept", "/");
    const options = { headers: headers, responseType: "blob" as "json" };

    try {
      const response: any = await firstValueFrom(
        this.http.get(
          `${environment.backend.account}/api/v2/invite-members/template`,
          options
        )
      );

      const blob = new Blob([response], { type: "text/plain" });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "invite-members-template.xlsx";
      link.click();

      // To clean up the memory
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error", error);
    }
  }

  // searchMembersCM(data, page) {
  //   return this.http
  //     .post(
  //       `${environment.backend.account}/api/company/searchMembers?page=${page}`,
  //       data,
  //     )
  //     .pipe(
  //       catchError((error: any) => {
  //         this.ehs.debug(error, "getCompanyMemberCM", "company", "s");
  //         throwError(() => new Error(error));
  //       }),
  //     );
  // }

  getCompanyDetails(): Observable<CompanyDetails> {
    return this.http
      .get<CompanyDetails>(`${environment.backend.account}/api/company/details`)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "getCompanyDetails", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }
  getCompanyDetailsV3() {
    return this.http
      .get<CompanyDetails>(`${environment.backend.account}/api/company-details`)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "getCompanyDetails", "company", "s");
          return throwError(() => new Error(error));
        })
      )
      .subscribe((resp: any) => {
        this.currentCompanySubj.next(resp.data);
      });
  }

  inviteMembersByEmailBulk(payload: {
    emails: string[];
  }): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/v2/company/inviteMultipleUsers`,
        payload
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "invite users by email", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  toggle2SVOption(action: boolean): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/company/manage2SV`,
        {
          activate: action,
        }
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "activateDeactivate2SV", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  // getJoinRequests(page, data) {
  //   return this.http
  //     .post(
  //       `${environment.backend.account}/api/company/joinRequests?page=${page}`,
  //       data,
  //     )
  //     .pipe(
  //       catchError((error: any) => {
  //         this.ehs.debug(error, "getJoinRequests", "company", "s");
  //         throwError(() => new Error(error));
  //       }),
  //     );
  // }

  deleteUser(payload: { user_id: number }): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/company/user/delete`,
        payload
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "deleteUser", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }
  deleteUsersBulk(payload: {
    user_ids: string[];
  }): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/v2/company/user/bulkDelete`,
        payload
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "deleteUsers bulk action", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  manageJoinRequestBulk(payload: {
    user_ids: string[];
    action: string;
  }): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/v2/company/joinRequest/bulkActions`,
        payload
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(
            error,
            "manage join requests bulk action",
            "company",
            "s"
          );
          return throwError(() => new Error(error));
        })
      );
  }

  manageJoinRequest(payload: { action: string; user_id: number }) {
    return this.http
      .post(`${environment.backend.account}/api/company/manageRequest`, payload)
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "manageJoinRequest", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  sendExcelSheet(formData: FormData): Observable<GenericResponse<any>> {
    return this.http
      .post<GenericResponse<any>>(
        `${environment.backend.account}/api/company/invite/spreadsheet`,
        formData
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "sendExcelSheet", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  exportMembers() {
    return this.http
      .get(`${environment.backend.account}/api/company/export-members`, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "exportMembers", "company", "s");
          return throwError(() => new Error(error));
        })
      )
      .subscribe(
        (response: any) => {
          console.log("response", response);
          const blob = new Blob([response.body], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // take the name from the response header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const fileName = "members.xlsx";

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();

          // To clean up the memory
          URL.revokeObjectURL(url);
        },
        (e) => {
          console.log(e);
        }
      );
  }

  getCompanySignupData(companyCode: string) {
    return this.http.get(
      `${environment.backend.account}/api/company/company-signup-data/${companyCode}`,
      {
        headers: { ignoreError: "true" },
      }
    );
  }

  regenerateSignupLink(data: any) {
    return this.http
      .post(
        `${environment.backend.account}/api/company/regenerate-signup-link`,
        data
      )
      .pipe(
        catchError((error: any) => {
          this.ehs.debug(error, "regenerateSignupLink", "company", "s");
          return throwError(() => new Error(error));
        })
      );
  }

  private addPaginattionParams(url: string, data: any) {
    if (data.perPage) {
      url += `&perPage=${data.perPage}`;
    }
    if (data.sortByType) {
      url += `&sortByType=${data.sortByType}`;
    }
    if (data.sortByValue) {
      url += `&sortByValue=${data.sortByValue}`;
    }
    if (data.filterByValue) {
      url += `&filterByValue=${data.filterByValue}`;
    }
    if (data.status) {
      url += `&status=${data.status}`;
    }
    return url;
  }
}
